<template>
    <page-header-wrapper>
        <template v-slot:content>
            <div class="page-header-content">
                <a-card :bordered="false" class="content">
                    <div class="table-page-search-wrapper">
                        <a-form layout="inline">
                            <a-row :gutter="48">
                                <a-col :md="6" :sm="18">
                                    <a-form-item label="学校">
                                        <a-select
                                            v-model="queryParams.universityId"
                                            v-hasPermi="['organization:university:list']"
                                            @change="
                                                (e) => {
                                                    getDepartmentList().then(getGradeList().then(getSpecializeList()).then(getClassList()))
                                                }
                                            "
                                        >
                                            <a-select-option
                                                v-for="item in universityList"
                                                :value="item.universityId"
                                                :key="item.universityId"
                                                >{{ item.universityName }}</a-select-option
                                            >
                                        </a-select>
                                    </a-form-item>
                                </a-col>
                                <a-col :md="6" :sm="18">
                                    <a-form-item label="分院">
                                        <a-select
                                            v-model="queryParams.departmentId"
                                            v-hasPermi="['organization:department:list']"
                                            @change="
                                                (e) => {
                                                    getGradeList().then(getSpecializeList().then(getClassList()))
                                                }
                                            "
                                        >
                                            <a-select-option
                                                v-for="item in departmentList"
                                                :value="item.departmentId"
                                                :key="item.departmentId"
                                                >{{ item.departmentName }}</a-select-option
                                            >
                                        </a-select>
                                    </a-form-item>
                                </a-col>
                                <a-col :md="6" :sm="18">
                                    <a-form-item label="年级">
                                        <a-select
                                            v-model="queryParams.gradeId"
                                            v-hasPermi="['organization:grade:list']"
                                            @change="
                                                (e) => {
                                                    getSpecializeList().then(getClassList())
                                                }
                                            "
                                        >
                                            <a-select-option
                                                v-for="item in gradeList"
                                                :value="item.gradeId"
                                                :key="item.gradeId"
                                                >{{ item.gradeName }}</a-select-option
                                            >
                                        </a-select>
                                    </a-form-item>
                                </a-col>
                            </a-row>
                            <a-row :gutter="48">
                                <a-col :md="6" :sm="18">
                                    <a-form-item label="专业">
                                        <a-select
                                            v-model="queryParams.specializeId"
                                            v-hasPermi="['organization:specialize:list']"
                                            @change="
                                                (e) => {
                                                    getClassList()
                                                }
                                            "
                                        >
                                            <a-select-option
                                                v-for="item in specializeList"
                                                :value="item.specializeId"
                                                :key="item.specializeId"
                                                >{{ item.specializeName }}</a-select-option
                                            >
                                        </a-select>
                                    </a-form-item>
                                </a-col>
                                <a-col :md="6" :sm="18">
                                    <a-form-item label="班级名称">
                                        <a-input placeholder="请输入" v-model="queryParams.specializeName" />
                                    </a-form-item>
                                </a-col>
                                <a-col :md="6" :sm="18">
                                    <span class="table-page-search-submitButtons">
                                        <a-button @click="handleQuery" type="primary">查询</a-button>
                                        <a-button @click="resetQuery" style="margin-left: 8px">重置</a-button>
                                    </span>
                                </a-col>
                            </a-row>
                        </a-form>
                    </div>
                    <div class="table-page-operator-wrapper">
                        <a-button
                            @click="handleAddClassClick"
                            type="primary"
                            v-hasPermi="['organization:class:add']"
                            ghost
                            >新增</a-button
                        >
                    </div>
                    <!-- 表格 -->
                    <a-table
                        ref="table"
                        :columns="columns"
                        :loading="tableLoading"
                        :data-source="classList"
                        row-key="classId"
                        :pagination="false"
                    >
                        <!-- 插槽指向状态 -->
                        <span slot="logo" slot-scope="text">
                            <img v-if="text" :src="config.staticResourceDomain + text" alt="avatar" class="avatar" />
                        </span>
                        <span slot="status" slot-scope="text">
                            <a-badge :status="text | statusTypeFilter" :text="text | statusFilter" />
                        </span>
                        <!-- 更多选择 -->
                        <span slot="action" slot-scope="text, record">
                            <a
                                @click="
                                    (e) => {
                                        $router.push({
                                            path: '/staff/student',
                                            query: { uid: record.universityId, did:record.departmentId, gid:record.gradeId, sid:record.specializeId, cid:record.classId},
                                        })
                                    }
                                "
                                v-hasPermi="['organization:student:list']"
                                >学生</a
                            >
                            <a-divider type="vertical" />
                            <a
                                @click="$refs.createModal.show(record, true)"
                                v-hasPermi="['organization:class:query']"
                                >详细</a
                            >
                            <a-divider type="vertical" />
                            <a
                                @click="$refs.createModal.show(record, false)"
                                v-hasPermi="['organization:class:edit']"
                                >编辑</a
                            >
                            <a-divider type="vertical" />
                            <a
                                href="javascript:;"
                                v-hasPermi="['organization:class:remove']"
                                @click="handleDelete(record)"
                                >删除</a
                            >
                            <a-dropdown v-if="false">
                                <a class="ant-dropdown-link"> 更多 <a-icon type="down" /> </a>
                                <a-menu slot="overlay">
                                    <a-menu-item>
                                        <a
                                            href="javascript:;"
                                            v-hasPermi="['organization:class:remove']"
                                            @click="handleDelete(record)"
                                            >删除</a
                                        >
                                    </a-menu-item>
                                </a-menu>
                            </a-dropdown>
                        </span>
                    </a-table>
                    <!-- 底部分页按钮 -->
                    <a-pagination
                        class="ant-table-pagination"
                        v-model="current"
                        :page-size-options="pageSizeOptions"
                        :total="total"
                        show-size-changer
                        :page-size="pageSize"
                        @showSizeChange="onShowSizeChange"
                        @change="currentPageChange"
                    >
                        <template slot="buildOptionText" slot-scope="props">
                            <span v-if="props.value !== '50'">{{ props.value }}条/页</span>
                            <span v-if="props.value === '50'">全部</span>
                        </template>
                    </a-pagination>
                    <!-- 创建/编辑部门,单独封装了组件 -->
                    <create-form ref="createModal" @handle-success="handleOk" />
                </a-card>
            </div>
        </template>
    </page-header-wrapper>
</template>

<script>
// import { PageHeaderWrapper } from '@ant-design-vue/pro-layout'
import { listUniversity } from '@/api/rongyi/organization/university'
import { listDepartment } from '@/api/rongyi/organization/department'
import { listGrade } from '@/api/rongyi/organization/grade'
import { listSpecialize } from '@/api/rongyi/organization/specialize'
import { listClass,delClass } from '@/api/rongyi/organization/class'
import { isEmptyStr } from '@/utils/ruoyi'
import CreateForm from './modules/CreateForm'
import rryConfig from '@/../config/rongyiYun.config'
const statusMap = {
    0: {
        status: 'processing',
        text: '正常',
    },
    1: {
        status: 'default',
        text: '停用',
    },
}
export default {
    name: 'class',
    components: {
        CreateForm,
    },
    data() {
        return {
            config: rryConfig,
            universityList:[],
            departmentList:[],
            gradeList:[],
            specializeList:[],
            classList:[],
            // 分页数据(默认第一页):
            pageSizeOptions: ['10', '20', '30', '40', '50'],
            current: 1,
            pageSize: 10,
            total: 0,
            statusMap: [],
            tableLoading: false,
            // 表格树数据
            // 部门树选项
            deptOptions: [],
            // 状态数据字典
            statusOptions: [],
            // 查询参数
            queryParams: {
                universityId:undefined,
                departmentId:undefined,
                gradeId:undefined,
                className:undefined

            },
            // 表头
            columns: [
                {
                    title: '名称',
                    dataIndex: 'className',
                },
                // {
                //     title: '联系人',
                //     dataIndex: 'linkman',
                // },
                // {
                //     title: '联系电话',
                //     dataIndex: 'linkmanPhone',
                // },
                // {
                //     title: '邮箱',
                //     dataIndex: 'email',
                // },
                {
                    title: '操作',
                    width: '150',
                    dataIndex: 'action',
                    scopedSlots: { customRender: 'action' },
                },
            ],
            // 表格多选
            selectedRowKeys: [],
            selectedRows: [],
            rowSelection: {
                onChange: (selectedRowKeys, selectedRows) => {
                    this.selectedRowKeys = selectedRowKeys
                    this.selectedRows = selectedRows
                },
                onSelect: (record, selected, selectedRows) => {},
                onSelectAll: (selected, selectedRows, changeRows) => {},
            },
        }
    },
    // 状态过滤
    filters: {
        statusFilter(type) {
            return statusMap[type].text
        },
        statusTypeFilter(type) {
            return statusMap[type].status
        },
    },
    created() {
        this.queryParams.universityId = this.$route.query.uid;
        this.queryParams.departmentId = this.$route.query.did;
        this.queryParams.gradeId = this.$route.query.gid;
        this.queryParams.specializeId = this.$route.query.sid;
        this.getUniversityList().then(this.getDepartmentList().then(this.getGradeList().then(this.getSpecializeList().then(this.getClassList))))
        // this.getDicts('sys_normal_disable').then(response => {
        //   this.statusOptions = response.data
        // })
        // console.log(this.$store.state);
    },
    watch: {
        '$route' (to, from) {
            if (this.$route.query.sid) {
                this.getClassList();
            }
        }
    },
    methods: {
        getUniversityList() {
            const p = new Promise((resolve,reject)=>{
                listUniversity().then((response) => {
                    if(response.code == 200){
                        this.universityList = response.rows
                        resolve(response)
                    }else{
                        reject(response.msg)
                    }
                })
            });
            return p;
        },
        getDepartmentList() {
            const p = new Promise((resolve,reject)=>{
                if(isEmptyStr(this.queryParams.universityId)){
                    reject("universityId is empty");
                }else{
                    const param={
                        universityId:this.queryParams.universityId
                    }
                    listDepartment(param).then((response) => {
                        if(response.code == 200){
                            this.departmentList = response.rows
                            resolve(response)
                        }else{
                            reject(response.msg)
                        }
                    })
                }
            });
            return p;
        },
        getGradeList(){
            const p = new Promise((resolve,reject)=>{
                if(isEmptyStr(this.queryParams.universityId)){
                    reject("universityId is empty");
                }else if(isEmptyStr(this.queryParams.departmentId)){
                    reject("departmentId is empty");
                }else{
                    const param={
                        universityId:this.queryParams.universityId,
                        departmentId:this.queryParams.departmentId
                    }
                    listGrade(param).then((response) => {
                        if(response.code == 200){
                            this.gradeList = response.rows;
                            resolve(response)
                        }else{
                            reject(response.msg)
                        }
                    });
                }
            });
            return p;
        },
        getSpecializeList() {
            const p = new Promise((resolve,reject)=>{
                if(isEmptyStr(this.queryParams.universityId)){
                    reject("universityId is empty");
                }else if(isEmptyStr(this.queryParams.departmentId)){
                    reject("departmentId is empty");
                }else if(isEmptyStr(this.queryParams.gradeId)){
                    reject("gradeId is empty");
                }else{
                    const param={
                        universityId:this.queryParams.universityId,
                        departmentId:this.queryParams.departmentId,
                        gradeId:this.queryParams.gradeId,
                    }
                    listSpecialize(param).then((response) => {
                        if(response.code == 200){
                            this.specializeList = response.rows;
                            resolve(response)
                        }else{
                            reject(response.msg)
                        }
                    });
                }
            });
            return p;
        },
        getClassList() {
            const p = new Promise((resolve,reject)=>{
                if(isEmptyStr(this.queryParams.universityId)){
                    reject("universityId is empty");
                }else if(isEmptyStr(this.queryParams.departmentId)){
                    reject("departmentId is empty");
                }else if(isEmptyStr(this.queryParams.gradeId)){
                    reject("gradeId is empty");
                }else if(isEmptyStr(this.queryParams.specializeId)){
                    reject("specializeId is empty");
                }else{
                    const param={
                        universityId:this.queryParams.universityId,
                        departmentId:this.queryParams.departmentId,
                        gradeId:this.queryParams.gradeId,
                        specializeId:this.queryParams.specializeId,
                    }
                    listClass(param).then((response) => {
                        if(response.code == 200){
                            this.classList = response.rows;
                            resolve(response)
                        }else{
                            reject(response.msg)
                        }
                    });
                }
            });
            return p;
        },
        /** 转换部门数据结构 */
        // normalizer(node) {
        //     if (node.children && !node.children.length) {
        //         delete node.children
        //     }
        //     return {
        //         id: node.deptId,
        //         label: node.deptName,
        //         children: node.children,
        //     }
        // },
        // 字典状态字典翻译
        statusFormat(row, column) {
            return this.selectDictLabel(this.statusOptions, row.status)
        },
        /** 搜索按钮操作 */
        handleQuery() {
            this.getClassList()
        },
        /** 重置按钮操作 */
        resetQuery() {
            this.resetForm('queryForm')
            this.handleQuery()
        },
        handleAddClassClick(){
            console.log("this.queryParams")
            console.log(this.queryParams)
            if(isEmptyStr(this.queryParams.universityId)){
                this.$message.warning("请先选择学校！")
            }else if(isEmptyStr(this.queryParams.departmentId)){
                this.$message.warning("请先选择分院！")
            }else if(isEmptyStr(this.queryParams.gradeId)){
                this.$message.warning("请先选择年级！")
            }else if(isEmptyStr(this.queryParams.specializeId)){
                this.$message.warning("请先选择年级！")
            }else{
                this.$refs.createModal.show(null, false, this.queryParams.universityId,this.queryParams.departmentId,this.queryParams.gradeId,this.queryParams.specializeId);
            }
        },
        // 新增/修改框事件
        handleOk() {
            this.getClassList()
        },
        /** 删除按钮操作 */
        handleDelete(row) {
            const that = this
            this.$confirm({
                title: '警告',
                content: `真的要删除 名为${row.className}的数据项吗?`,
                okText: '删除',
                okType: 'danger',
                cancelText: '取消',
                onOk() {
                    delClass(row.classId).then((response) => {
                        if (response.code === 200) {
                            that.$message.success('删除成功!')
                            that.getClassList()
                        } else {
                            that.$message.error(response.msg)
                        }
                    })
                },
            })
        },
        /** pageSize 变化的回调 */
        onShowSizeChange(current, pageSize) {
            this.current = current
            this.pageSize = pageSize
            this.queryParams.pageSize = pageSize
            this.queryParams.pageNum = current
            this.getClassList()
        },
        /** 页码改变的回调 */
        currentPageChange(current, pageSize) {
            this.current = current
            this.pageSize = pageSize
            this.queryParams.pageSize = pageSize
            this.queryParams.pageNum = current
            this.getClassList()
        },
    },
}
</script>
<style scoped>
.avatar {
    width: 32px;
    height: 32px;
}
</style>